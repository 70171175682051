<template>
  <div class="container-fluid p-0">
    <div class="page-headermb">
      <h2 class="main-content-title">Notifications
        {{ notificationCount > 0 ? '(' + notificationCount + ')' : '' }}
      </h2>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 text-right mb-3"
            v-if="notifications.length > 0">
            <el-button type="success"
              class="btn-sm" plain
              @click="markAllRead">
              Mark All As Read
            </el-button>
          </div>
          <div class="col-lg-12 col-md-12 col-12">
            <div v-if="notifications.length < 1"
              class="no-data-box text-center py-5">
              <div class="no-data-content">
                <img class="img-fluid mb-2"
                  src="../../assets/img/no_notification.jpg"
                  alt="No Notification" />
                <h3 class="m-0">No notifications
                  yet!</h3>
              </div>
            </div>
            <div v-else
              class="notification-container"
              v-for="(alert, index) in notifications"
              :key="index"
              :class="alert.is_read ? '' : 'unread'"
              @click="markRead(alert)">
              <h3>{{ alert.title }}</h3>
              <p>{{ alert.description }}</p>
              <!-- <small>29 May 2022, 10:22 AM</small> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Alert",
  computed: {
    ...mapGetters("notifications", ["notifications", "notificationCount"]),
  },
  methods: {
    ...mapActions("notifications", [
      "getNotifications",
      "countNotification",
      "markAllAsRead",
      "markAsRead"
    ]),

    markAllRead() {
      this.markAllAsRead("notification").then(() => {
        this.getNotifications();
      });
    },
    markRead(notification) {
      this.markAsRead({ notification: notification, notificationType: 'notification' })
    }
  },
  mounted() {
    this.getNotifications();
    // this.countNotification("notification");
  },
};
</script>

<style scoped>

</style>